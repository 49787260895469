<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">등록고객관리
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc2 ml-2"  @click="getListData(1)" id="btnSearch">조회</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  등록고객목록
                </caption>
                <colgroup>
                  <col width="10%" />
                  <col width="50%" />
                  <col width="15%" />
                  <col width="27%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">건설사명</v-badge>
                      
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-autocomplete
                          :items="ctcpTypeCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          @change="changeDetailBussType(S_CTCP_CODE, true)"
                          v-model="S_CTCP_CODE"
                          hide-details
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">기간 조회 타입</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          :items="dateSearchTypeArr"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          @change="fnSdateSType"
                          v-model="S_DATE_SEARCH_TYPE"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">사업지명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          :items="bussTypeCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          v-model="S_BUSS_CODE"
                          @change="changeDetailCmpType(S_BUSS_CODE, true)"
                          :disabled="bussTypeCd.length == 0 ? true : false"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">{{ DATE_TEXT }} 기간</v-badge>
                    </th>
                    <td>
                      <v-menu 
                        v-model="menu1" 
                        :close-on-content-click="false" 
                        :nudge-right="40"
                        transition="scale-transition" 
                        offset-y 
                        min-width="auto" 
                        content-class="calendar-modal"
                        color="#FFF"
                        maxlength="12"
                      >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateRangeText"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          hide-details
                          class="form-inp icon-calendar full"
                          append-icon="svg-calendar"
                        ></v-text-field>
                      </template>
                      <div class="datepicker-range">
                        <v-date-picker
                          v-model="dates[0]"
                          no-title
                          color="#F4B228"
                          locale="ko-KR"
                          :first-day-of-week="0" 
                          :day-format="mixin_getDate" 
                          @change="startDate"
                        ></v-date-picker>
                        <v-date-picker
                          v-model="dates[1]"
                          color="#F4B228"
                          no-title
                          show-current="false"
                          :min="dates[0]"
                          locale="ko-KR"
                          :first-day-of-week="0" 
                          :day-format="mixin_getDate" 
                          @change="endDate"
                        ></v-date-picker>
                      </div>
                      <div class="text-center pt-3 pb-3">
                        <v-btn
                          outlined
                          small
                          class="btn-point ml-3"
                          @click="menu1 = false"
                          >확인</v-btn
                        >
                      </div>
                      </v-menu>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                       <v-badge dot color="#EF9191">캠페인명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          :items="cmpTypeCd"
                          item-text="CMP_NAME"
                          item-value="CMP_ID"
                          outlined
                          v-model="S_CMP_CODE"
                          @change="changeCmp(S_CMP_CODE)"
                          :disabled="cmpTypeCd.length == 0 ? true : false"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      {{ S_CMP_DATES_TEXT }}
                    </th>
                    <td >
                      <div>
                        <v-text-field
                          class="form-inp full"
                          outlined
                          hide-details
                          readonly
                          v-model="S_CMP_DATES"
                        >
                        </v-text-field>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" >
                      고객경로
                    </th>
                    <td >
                      <div class="form-inp full" >
                        <v-select
                          :items="mixin_common_code_get(this.allCodeList, 'HLW_CUST_TRGT_CD', '전체')"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          v-model="S_TRGT_GUB_NAME"
                          @change="getListData(1)"
                        >
                        </v-select>
                      </div>
                    </td>
                    <th scope="row" >
                      전화번호
                    </th>
                    <td >
                      <div>
                        <v-text-field
                          class="form-inp full"
                          outlined
                          hide-details
                          v-model="S_USER_PHONE"
                          v-on:keyup.enter="getListData(1)"
                        >
                        </v-text-field>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                        미동의자 포함
                    </th>
                    <td>
                      <v-checkbox 
                        :on-icon="'svg-chk-on'" 
                        :off-icon="'svg-chk-off'" 
                        class="form-inp table-form-tag"
                        id="agreYn" 
                        v-model="S_AGRE_YN">
                      </v-checkbox>
                    </td>
                    <th scope="row">
                        방문예약취소 포함
                    </th>
                    <td>
                      <v-checkbox 
                        :on-icon="'svg-chk-on'" 
                        :off-icon="'svg-chk-off'" 
                        class="form-inp table-form-tag"
                        id="useYn" 
                        v-model="S_USE_YN">
                      </v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">등록고객 목록
              <div class="ml-auto">
              <v-btn
                outlined
                class="btn-default ml-2"
                @click="downloadExcel('등록고객관리', '등록고객 목록 조회')"
              >엑셀다운로드</v-btn>
              </div>
            </h1>
            
            <div class="mt-2">
              <v-data-table
                dense
                height="400px"
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="itemsPerPage"
                item-key="IDX"
                hide-default-footer
                class="grid-default"
                fixed-header
                id="mainTable"
                @click:row="gridRowClick"
                v-model="selGroupCd"
                :item-class="clickActive"
                no-data-text="검색된 결과가 없습니다."
              >
                <template v-slot:item.IMG_QUTN_RPLY={item}>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          dark 
                          small 
                          class="btn-main-search pa-0 ml-2" 
                          min-width="32px" height="32px" 
                          plain
                          :disabled="item.IMG_QUTN_RPLY=='' ? true : false"
                          v-bind="attrs"
                          v-on="on">
                          <v-icon small class="svg-main-search2"></v-icon>
                        </v-btn>
                      </template>
                      <span>
                        <img
                          v-if="item.IMG_QUTN_RPLY!=''"
                          v-for="(imgItem, idx) in item.IMG_QUTN_RPLY.split(',')"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          :src="baseUrl+imgDefPath+imgItem.split(':')[1]"
                          @error="replaceImg"
                          :width=450 :height=650 />
                          
                      </span>
                    </v-tooltip>

                  </div>
                </template>
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ totCount }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  @input="getListData(page)"
                ></v-pagination>
              </div>
            </div>
          </div>

        </div>
        <!-- 알림톡 상세내용 -->
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">등록고객 상세내용
            <div class="ml-auto">
              <v-btn outlined class="btn-etc ml-2"
                @click="fnDeleteInfo" 
                v-if="deleteBtnBool"
              >
                삭제
              </v-btn>
              <v-btn
                outlined
                class="btn-default ml-2"
                @click="downloadExcelDetailInfo('등록고객 상세내용 조회')"
              >엑셀다운로드</v-btn>
              </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  파트너 정보 항목
                </caption>
                <colgroup>
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">건설사명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_CTCP_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">사업지명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_BUSS_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">캠페인명
                    </th>
                    <td>
                      <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_CMP_NAME"
                      >
                      </v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">고객명
                    </th>
                    <td style="width:100%">
                      <div class="form-inp full">
                        <v-textarea
                        width:500px
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_USER_NAME"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">전화번호
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_USER_PHONE"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">등록일시
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_REG_DT"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">예약일시
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_RSVT_DT"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    <th scope="row">개인정보수집동의</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_PRSN_AGRE_YN"
                        ></v-textarea>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >마케팅활용동의</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_MKTG_AGRE_YN"
                        ></v-textarea>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >방문등록타입</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_VIST_GUB_NAME"
           
                      >
                      </v-textarea>
                    </td> 
                  </tr>
                  <tr>
                    <th scope="row" >고객경로</th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        readonly
                        v-model="T_TRGT_GUB_NAME"
                      >
                      </v-textarea>
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "MENU_CUM0100", //name은 'MENU_' + 파일명 조합
    components: {
    },
    data() {
      return {
        deleteBtnBool:false,
        menu1:false,
        dates: [
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        ],
        bussTypeCd:[],
        ctcpTypeCd:[],
        cmpTypeCd:[],
        bussTypeCdT:[],
        ctcpTypeCdT:[],
        CmdCdT:[],
        selGroupCd:[],
        gridExcelDataText: [],
        gridDataText: [],
        gridDataTextInfo: [],
        imgSrc:'/campaign/manage/images/',
        baseUrl:process.env.VUE_APP_API_URL,
        imgDefPath:'api/file/hlw/images/',
        dateSearchTypeArr:[
          {
            CD:''
            , CD_NM:'등록&예약'
          },
          {
            CD:'REG'
            , CD_NM:'등록일'
          },
          {
            CD:'RES'
            , CD_NM:'예약일'
          },
        ],
        S_DATE_SEARCH_TYPE:'',
        
        BUSS_CODE: "",
        S_CTCP_CODE: "",
        S_BUSS_CODE: "",
        S_CMP_CODE: "",
        S_USER_PHONE: "",
        S_TRGT_GUB_NAME: "",
        S_CMP_DATES_TEXT:'',
        S_CMP_DATES:'',
        S_AGRE_YN:true,
        S_USE_YN:true,
        DATE_TEXT:'',

        ROW_NUMBER:"",
        T_CTCP_NAME: "",
        T_BUSS_NAME: "",
        T_CMP_NAME: "",
        T_USER_NAME: "",
        T_USER_PHONE: "",
        T_REG_DT: "",
        T_RSVT_DT: "",
        T_PRSN_AGRE_YN: "",
        T_MKTG_AGRE_YN: "",
        T_VIST_GUB_NAME: "",
        T_TRGT_GUB_NAME: "",
        USER_AUTH_CHK : "",
        allCodeList: "",
        EXCEL_CMP_NAME:"",
        T_MKTG_AGRE_YN  :"",
        TQUTN_ITEM_LIST:"",
        TCHK_QUTN_RPLY   :"",
        TSEL_QUTN_RPLY  :"",
        TTXT_QUTN_RPLY :"",
        TETC_QUTN_RPLY  :"",
        gridDataHeaders: [
          { text: "No",              value: "IDX",           align: "center", width: "50px", sortable: true  },
          { text: "캠페인명",        value: "CMP_NAME",               align: "center", width: "160px", sortable: true },
          { text: "고객명",           value: "CUST_NAME",             align: "center", width: "100px", sortable: true },
          { text: "전화번호",         value: "CUST_PHONE",            align: "center", width: "100px", sortable: true },
          { text: "방문등록타입",      value: "VIST_GUB_NAME",        align: "center", width: "100px", sortable: true },
          { text: "고객경로",          value: "TRGT_GUB_NAME",        align: "center", width: "100px", sortable: true },
          { text: "이미지",           value: "IMG_QUTN_RPLY",         align: "center", width: "75px", sortable: true },

          { text: "건설사명",         value: "CTCP_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사업지명",         value: "BUSS_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "등록일시",           value: "REG_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "예약일시",           value: "CNSL_RSVT_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
        ],
        excelDataHeaders: [
          { text: "건설사명",         value: "CTCP_NAME",       align: "center", width: "200px"},
          { text: "사업지명",         value: "BUSS_NAME",       align: "center", width: "300px"},
          { text: "캠페인명",        value: "CMP_NAME",          align: "center", width: "300px" },
          { text: "고객명",           value: "CUST_NAME",         align: "center", width: "120px" },
          { text: "전화번호",         value: "CUST_PHONE",        align: "center", width: "280px"},
          { text: "등록일시",           value: "REG_DT",           align: "center", width: "400px" },
          { text: "예약일시",           value: "CNSL_RSVT_DT",     align: "center", width: "400px" },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",    align: "center", width: "120px" },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",      align: "center", width: "120px"},
          { text: "방문등록타입",      value: "VIST_GUB_NAME",        align: "center", width: "100px"},
          { text: "고객경로",          value: "TRGT_GUB_NAME",        align: "center", width: "100px" },
        ],

        page: 1,
        pageCount: 0,
        totCount: 0,
        itemsPerPage: 20,
        totalVisible: 10,
      };
    },

    methods: {

      fnSdateSType() {
        if(this.S_DATE_SEARCH_TYPE=='REG') {
          this.DATE_TEXT='등록일';
        }else if(this.S_DATE_SEARCH_TYPE=='RES') {
          this.DATE_TEXT='예약일';
        }else{
          this.DATE_TEXT='';
        }
      },

      startDate(e) {
        this.dates[0] = e;
      },

      endDate(e) {
        this.dates[1] = e;
      },

      activeRow(row, value) {
        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },
      
      disableRow(row, value) {
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },

      replaceImg(e) {
        e.target.src = require(`@/assets/logo.png`);
      },

      cleanHeaders(){
        this.gridDataHeaders= [
          { text: "No",              value: "IDX",           align: "center", width: "50px", sortable: true  },
          { text: "캠페인명",        value: "CMP_NAME",               align: "center", width: "160px", sortable: true },
          { text: "고객명",           value: "CUST_NAME",             align: "center", width: "100px", sortable: true },
          { text: "전화번호",         value: "CUST_PHONE",            align: "center", width: "100px", sortable: true },
          { text: "방문등록타입",      value: "VIST_GUB_NAME",        align: "center", width: "140px", sortable: true },
          { text: "고객경로",          value: "TRGT_GUB_NAME",        align: "center", width: "100px", sortable: true },
          { text: "이미지",           value: "IMG_QUTN_RPLY",         align: "center", width: "75px", sortable: true },

          { text: "건설사명",         value: "CTCP_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사업지명",         value: "BUSS_NAME",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "등록일시",           value: "REG_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "예약일시",           value: "CNSL_RSVT_DT",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "개인정보수집동의",     value: "PRSN_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "마케팅활용동의",     value: "MKTG_AGRE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
        ];
      },

      //**********************************
      //      등록 고객 조회 호출
      //**********************************
      async getListData(pg) {

        let pbBool = true;
        this.page = pg;
        this.gridDataText = [];
        this.gridDataTextInfo = [];

        let bussCd = [{ "USER_BUSS": [] }];

        for(let i = 0; i < this.bussTypeCd.length; i++) bussCd[0]["USER_BUSS"].push(this.bussTypeCd[i].CD)

        if (this.USER_AUTH_CHK == "MANAGER") {

          if (this.mixin_isEmpty(this.S_BUSS_CODE)) {
            this.common_alert('사업지명을 선택해주세요.', 'chk');
            return;
          }
        }

        // Buss code가 init 되기 전에 func call이 먼저 되는 케이스.
        // 시스템매니저, 매니저의 경우 BUSS_CODE를 특정할 수 없어 예외.
        if (!["MANAGER", "SYSTEMMANAGER"].includes(this.USER_AUTH_CHK) && bussCd == "" ) return;

        let requestData = {
          headers: {},
          sendData: {},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/cnslr-custRegist/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.cnslr-custRegist.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        let plCnt = 0;
        if(this.page > 1) {
          plCnt = 1;
        }

        let strRow = ((this.page - 1) * (this.itemsPerPage)) + plCnt;
        let endRow = this.page * this.itemsPerPage;

        requestData.sendData["STR_ROW"] = strRow;
        requestData.sendData["END_ROW"] = endRow;

        requestData.sendData["PART_TYPE"] = 'PO';        //분양:PO, 입주:MI 구분
        requestData.sendData["CTCP_CODE"] = this.S_CTCP_CODE;        //건설사코드
        requestData.sendData["BUSS_CODE"] = this.S_BUSS_CODE;        //사업지코드
        requestData.sendData["USER_BUSS"] = bussCd;                // 검색용 사업지 코드
        requestData.sendData["CMP_ID"] = this.S_CMP_CODE;         //캠페인코드
        requestData.sendData["CUST_PHONE"] = this.S_USER_PHONE;
        requestData.sendData["TRGT_GUB_CD"] = this.S_TRGT_GUB_NAME;
        requestData.sendData["START_DT"] = this.dates[0];
        requestData.sendData["END_DT"] = this.dates[1];
        requestData.sendData["S_DATE_SEARCH_TYPE"] = this.S_DATE_SEARCH_TYPE;


        if (this.S_AGRE_YN) {
          requestData.sendData["AGRE_YN"] = "Y";
        } else {
          requestData.sendData["AGRE_YN"] = "N";
        }
        if (this.S_USE_YN) {
          requestData.sendData["USE_YN"] = "Y";
        } else {
          requestData.sendData["USE_YN"] = "N";
        }

        let response = await this.common_postCall(requestData, pbBool);

        if (!response.HEADER.ERROR_FLAG) {
          this.cleanHeaders();
          this.cleanValidation();   //오른쪽 내용 초기화

          let data = response.DATA;
          if(!this.mixin_isEmpty(data)) {
            this.totCount = data[0].TOT_CNT;

            this.pageCount = Math.ceil(this.totCount / this.itemsPerPage);

            this.gridDataText = data;
            this.EXCEL_CMP_NAME = this.gridDataText[0]["CMP_NAME"];
            
            let cnt = 0;

            for(var i in this.gridDataText){
              this.gridDataText[i]["IDX"] = Math.floor(Number(this.gridDataText[i].IDX));

              if(this.gridDataText[i]["QUTN_ITEM_NAME"] !== ''){
                if ( cnt === 0 ){
                  for(var d in this.gridDataText[i]["QUTN_ITEM_NAME"]){              
                    this.gridDataHeaders.push({ text: "질문", value:"QUTN_ITEM_NAME" + d,  align: "center", width: "120px", sortable: true },)
                    this.gridDataHeaders.push({ text: "답", value: "QUTN_ITEM_DETL_CODE" + d,  align: "center", width: "120px", sortable: true },)  
                    this.excelDataHeaders.push({ text: "질문"+ d, value:"QUTN_ITEM_NAME" + d,  align: "center", width: "120px", sortable: true },)
                    this.excelDataHeaders.push({ text: "답"+ d, value: "QUTN_ITEM_DETL_CODE" + d,  align: "center", width: "120px", sortable: true },)  
                    
                    this.gridDataText[i]["QUTN_ITEM_NAME"+[d]] = this.gridDataText[i]["QUTN_ITEM_NAME"][d];
                    this.gridDataText[i]["QUTN_ITEM_DETL_CODE"+[d]] = this.gridDataText[i]["QUTN_ITEM_DETL_CODE"][d];

                    cnt = cnt + 1;  
                  }    

                }else{
                  for(var c in this.gridDataText[i]["QUTN_ITEM_NAME"]){
                    if ( c >= cnt  ){
                      this.gridDataHeaders.push({ text: "질문", value:"QUTN_ITEM_NAME" + c,  align: "center", width: "120px", sortable: true },)
                      this.gridDataHeaders.push({ text: "답", value: "QUTN_ITEM_DETL_CODE" + c,  align: "center", width: "120px", sortable: true },)  
                      this.excelDataHeaders.push({ text: "질문"+ c, value:"QUTN_ITEM_NAME" + c,  align: "center", width: "120px", sortable: true },)
                      this.excelDataHeaders.push({ text: "답"+ c, value: "QUTN_ITEM_DETL_CODE" + c,  align: "center", width: "120px", sortable: true },)  
                      this.gridDataText[i]["QUTN_ITEM_NAME"+[c]] = this.gridDataText[i]["QUTN_ITEM_NAME"][c];
                      this.gridDataText[i]["QUTN_ITEM_DETL_CODE"+[c]] = this.gridDataText[i]["QUTN_ITEM_DETL_CODE"][c];
                      cnt = cnt + 1 ;
                    }else{
                      this.gridDataText[i]["QUTN_ITEM_NAME"+[c]] = this.gridDataText[i]["QUTN_ITEM_NAME"][c];
                      this.gridDataText[i]["QUTN_ITEM_DETL_CODE"+[c]] = this.gridDataText[i]["QUTN_ITEM_DETL_CODE"][c];

                    }
                  }
                }
              }
            }

          } else {
            this.pageCount = 0;
            this.totCount = 0;
          }
          
        } else {
          this.common_alert("시스템에 오류가 발생하였습니다.", "error");
          return;
        }

        this.deleteBtnBool=false;
      },

      async getListExcelData(fileNm, tableId) {

        let bussCd = [{ "USER_BUSS": [] }];
        for(let i = 0; i < this.bussTypeCd.length; i++) bussCd[0]["USER_BUSS"].push(this.bussTypeCd[i].CD)

        let requestData = {
          headers: {},
          sendData: {},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/cnslr-custRegist/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.cnslr-custRegist.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["PART_TYPE"] = 'PO';        //분양:PO, 입주:MI 구분
        requestData.sendData["CTCP_CODE"] = this.S_CTCP_CODE;        //건설사코드
        requestData.sendData["BUSS_CODE"] = this.S_BUSS_CODE;        //사업지코드
        requestData.sendData["USER_BUSS"] = bussCd;                // 검색용 사업지 코드
        requestData.sendData["CMP_ID"] = this.S_CMP_CODE;         //캠페인코드
        requestData.sendData["CUST_PHONE"] = this.S_USER_PHONE;
        requestData.sendData["TRGT_GUB_CD"] = this.S_TRGT_GUB_NAME;
        requestData.sendData["START_DT"] = this.dates[0];
        requestData.sendData["END_DT"] = this.dates[1];
        requestData.sendData["S_DATE_SEARCH_TYPE"] = this.S_DATE_SEARCH_TYPE;


        if (this.S_AGRE_YN) {
          requestData.sendData["AGRE_YN"] = "Y";
        } else {
          requestData.sendData["AGRE_YN"] = "N";
        }
        if (this.S_USE_YN) {
          requestData.sendData["USE_YN"] = "Y";
        } else {
          requestData.sendData["USE_YN"] = "N";
        }

        let response = await this.common_postCall(requestData);

        if (!response.HEADER.ERROR_FLAG) {
          this.cleanHeaders();
          this.cleanValidation();   //오른쪽 내용 초기화

          let data = response.DATA;
          if(!this.mixin_isEmpty(data)) {
            this.gridExcelDataText = data;
            this.EXCEL_CMP_NAME = this.gridExcelDataText[0]["CMP_NAME"];
            
            let cnt = 0;

            for(var i in this.gridExcelDataText){
              this.gridExcelDataText[i]["IDX"] = Math.floor(Number(this.gridExcelDataText[i].IDX));

              if(this.gridExcelDataText[i]["QUTN_ITEM_NAME"] !== ''){
                if ( cnt === 0 ){
                  for(var d in this.gridExcelDataText[i]["QUTN_ITEM_NAME"]){              
                    this.excelDataHeaders.push({ text: "질문"+ d, value:"QUTN_ITEM_NAME" + d,  align: "center", width: "120px", sortable: true },)
                    this.excelDataHeaders.push({ text: "답"+ d, value: "QUTN_ITEM_DETL_CODE" + d,  align: "center", width: "120px", sortable: true },)  
                    
                    this.gridExcelDataText[i]["QUTN_ITEM_NAME"+[d]] = this.gridExcelDataText[i]["QUTN_ITEM_NAME"][d];
                    this.gridExcelDataText[i]["QUTN_ITEM_DETL_CODE"+[d]] = this.gridExcelDataText[i]["QUTN_ITEM_DETL_CODE"][d];

                    cnt = cnt + 1;  
                  }    

                }else{
                  for(var c in this.gridExcelDataText[i]["QUTN_ITEM_NAME"]){
                    if ( c >= cnt  ){
                      this.excelDataHeaders.push({ text: "질문"+ c, value:"QUTN_ITEM_NAME" + c,  align: "center", width: "120px", sortable: true },)
                      this.excelDataHeaders.push({ text: "답"+ c, value: "QUTN_ITEM_DETL_CODE" + c,  align: "center", width: "120px", sortable: true },)  
                      this.gridExcelDataText[i]["QUTN_ITEM_NAME"+[c]] = this.gridExcelDataText[i]["QUTN_ITEM_NAME"][c];
                      this.gridExcelDataText[i]["QUTN_ITEM_DETL_CODE"+[c]] = this.gridExcelDataText[i]["QUTN_ITEM_DETL_CODE"][c];
                      cnt = cnt + 1 ;
                    }else{
                      this.gridExcelDataText[i]["QUTN_ITEM_NAME"+[c]] = this.gridExcelDataText[i]["QUTN_ITEM_NAME"][c];
                      this.gridExcelDataText[i]["QUTN_ITEM_DETL_CODE"+[c]] = this.gridExcelDataText[i]["QUTN_ITEM_DETL_CODE"][c];

                    }
                  }
                }
              }
            }
          } 

          //엑셀다운로드
          let fileName = this.EXCEL_CMP_NAME + '_' + fileNm + '_('+this.dates[0] + '~' +this.dates[1]+')';
          this.table_id = tableId;

          this.mixin_common_Excel(this.excelDataHeaders, this.gridExcelDataText, fileName, this.EXCEL_CMP_NAME.substring(0,30));
          
        } else {
          this.common_alert("시스템에 오류가 발생하였습니다.", "error");
          return;
        }

        this.deleteBtnBool=false;
      },

      fnDeleteInfo() {
        if(!this.mixin_isEmpty(this.T_USER_PHONE)) {
          let trgMsg='';
          if(!this.mixin_isEmpty(this.T_USER_NAME)) {
            trgMsg=this.T_USER_NAME;
          }else{
            trgMsg=this.T_USER_PHONE;
          }
          this.common_confirm('사업지:'+this.T_BUSS_NAME+'\n'+trgMsg+'님을 삭제하시겠습니까?', this.fnDeleteYes, null, null, null, 'chk');
        }else{
          this.common_alert('삭제할 대상을 선택해주세요.', 'chk');
        }
      },

      async fnDeleteYes() {
        let requestData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"]="/api/hlw/campaign/cnslr-custRegist/manage/delete";

        //sendData 초기화
        requestData.sendData["BUSS_CODE"]=this.BUSS_CODE
        requestData.sendData["CUST_PHONE"]=this.T_USER_PHONE

        let response=await this.common_postCall(requestData);
        let resData=response.DATA

        if (response.HEADER.ERROR_FLAG){
          this.common_alert("시스템에 오류가 발생하였습니다.","error");
        }else{
          this.common_alert('삭제되었습니다.');
          this.getListData(1);
        }
      },

      downloadExcel(fileNm, tableId){
        if(!this.mixin_isEmpty(this.gridDataText)) {
          this.getListExcelData(fileNm, tableId);
        } else {
          this.common_alert('목록 데이터를 조회후 다운로드 받아주세요.', 'chk');
          return;
        }
      },

      //엑셀다운로드(상세내용)
      downloadExcelDetailInfo(fileNm){

        if(!this.mixin_isEmpty(this.gridDataTextInfo)) {
          let jsonObj = JSON.parse(JSON.stringify(this.gridDataTextInfo)); //json string data를 object 형태로 파싱
          this.gridDataTextInfo = ([jsonObj]) ;
  
          let fileName =  this.EXCEL_USER_NAME + '_' + fileNm;
  
          this.mixin_common_Excel(this.excelDataHeaders,this.gridDataTextInfo, fileName, this.EXCEL_USER_NAME);
        } else {
          this.common_alert('목록 데이터를 클릭후 다운로드 받아주세요.', 'chk');
          return;
        }
      },

      //사업지 코드 가져오기
      async changeDetailBussType(ctcpTypeCd, isReload = false) {

        //캠페인 기간 데이터 초기화
        this.fnCmpInfoDataDef();

        this.bussTypeCd = [];
        this.cmpTypeCd = [];
        this.S_BUSS_CODE = "";
        this.S_CMP_CODE = "";

        if (!this.mixin_isEmpty(ctcpTypeCd)) {
          if (this.USER_AUTH_CHK ==  "SYSTEMMANAGER"){
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);
          }else{
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd,this.GE_USER_ID);
          }

          if(!this.mixin_isEmpty(this.bussTypeCd)) {
            this.bussTypeCd.unshift({ CD: "", CD_NM: "전체" });
          }

          if(this.bussTypeCd.length == 0){
            this.S_BUSS_CODE = "";
            this.S_CMP_CODE = "";
          }
        }

        if(isReload) this.getListData(1);
      },

      //캠페인 코드 가져오기
      async changeDetailCmpType(bussTypeCd, isReload = false) {

        //캠페인 기간 데이터 초기화
        this.fnCmpInfoDataDef();

        this.cmpTypeCd = [];
        this.S_CMP_CODE =  "";

        if (!this.mixin_isEmpty(bussTypeCd)) {
          this.cmpTypeCd = await this.mixin_cmpcode_get(bussTypeCd); 
          if(this.cmpTypeCd.length == 0){
            this.S_CMP_CODE = "";
          }

          if(!this.mixin_isEmpty(this.cmpTypeCd)) {
            this.cmpTypeCd.unshift({ CMP_ID: "", CMP_NAME: "전체" });
          }
        }

        if(isReload) this.getListData(1);
      },

      fnCmpInfoDataDef() {
        this.S_CMP_DATES='';
        this.S_CMP_DATES_TEXT='';
      },

      async changeCmp(cmpId) {

        let requestData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"]="/api/hlw/campaign/manage/data";

        //sendData 초기화
        requestData.sendData["CMP_ID"]=cmpId;

        let response=await this.common_postCall(requestData, false);
        let resData=response.DATA;

        if (response.HEADER.ERROR_FLAG){
          this.common_alert("시스템에 오류가 발생하였습니다.","error");
        }else{
          if(resData.length>0) {
            resData.forEach((element, i) => {
              this.S_CMP_DATES=element.CMP_STDT+' ~ '+element.CMP_ENDT
              this.S_CMP_DATES_TEXT='캠페인 기간'
            });
          }
        }

        this.getListData(1);
      },

      gridRowClick(item, row) {

        this.gridDataTextInfo = "";
        
        if (item != null) {
          if (row.isSelected == false) {
            this.activeRow(row, true);
          } else {
            this.disableRow(row, false);
          }
        }

        this.ROW_NUMBER       = item.IDX;
        this.BUSS_CODE        = item.BUSS_CODE
        this.T_CTCP_NAME      = item.CTCP_NAME;
        this.T_BUSS_NAME      = item.BUSS_NAME;
        this.T_CMP_NAME       = item.CMP_NAME;
        this.T_USER_NAME      = item.CUST_NAME;
        this.T_USER_PHONE     = item.CUST_PHONE;
        this.T_REG_DT         = item.REG_DT;
        this.T_RSVT_DT        = item.CNSL_RSVT_DT;
        this.T_PRSN_AGRE_YN   = item.PRSN_AGRE_YN;
        this.T_MKTG_AGRE_YN   = item.MKTG_AGRE_YN;
        this.T_VIST_GUB_NAME  = item.VIST_GUB_NAME;
        this.T_TRGT_GUB_NAME  = item.TRGT_GUB_NAME;
        this.TQUTN_ITEM_LIST  = item.TQUTN_ITEM_LIST; //설문 항목 리스트
        this.TCHK_QUTN_RPLY   = item.TCHK_QUTN_RPLY;  //체크박스형 설문 답변
        this.TSEL_QUTN_RPLY   = item.TSEL_QUTN_RPLY;  //선택형 설문 답변
        this.TTXT_QUTN_RPLY   = item.TTXT_QUTN_RPLY;  //테스트형 설문 답변
        this.TETC_QUTN_RPLY   = item.TETC_QUTN_RPLY;  //기타 설문 답변(체크박스형)
      
        this.gridDataTextInfo = item; //엑셀 body
        this.EXCEL_USER_NAME= item.CUST_NAME;
        this.deleteBtnBool=true
      },

      cleanValidation() {
        this.T_CTCP_NAME      = "";
        this.T_BUSS_NAME      = "";
        this.T_CMP_NAME       = "";
        this.T_USER_NAME      = "";
        this.T_USER_PHONE     = "";
        this.T_REG_DT         = "";
        this.T_RSVT_DT        = "";
        this.T_PRSN_AGRE_YN   = "";
        this.T_MKTG_AGRE_YN   = "";
        this.T_VIST_GUB_NAME  = "";
        this.T_TRGT_GUB_NAME  = "";

      },

      clickActive(item){
        //클릭 시, 하이라이트
        return item.IDX == this.ROW_NUMBER ? 'active' : '';
      },
    },

    async mounted() {
      let userGroupCd = this.user_role.userGroupCd;

      this.GE_USER_ID = this.user_id;

      let pArr = ['HLW_CTCP_CD', 'HLW_CNSLR_STAT_CD', 'HLW_CUST_TRGT_CD'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);

      if (userGroupCd == "SYSTEMMANAGER") {
        this.USER_AUTH_CHK = userGroupCd;
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '전체');
        this.cnslrStatCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CNSLR_STAT_CD');
      } else {
        this.USER_AUTH_CHK = userGroupCd;
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.GE_USER_ID);
        if (usserAuth.length > 0) {
          for (let i = 0; i < usserAuth.length; i++) {
            this.ctcpTypeCd.push({ CD_NM: usserAuth[i].CTCP_NAME, CD: usserAuth[i].CTCP_CODE });
          }
          this.bussTypeCd.push({ CD_NM: usserAuth[0].BUSS_NAME, CD: usserAuth[0].BUSS_CODE });
          this.cmpTypeCd = await this.changeDetailCmpType(this.bussTypeCd[0].CD);
          this.S_BUSS_CODE = usserAuth[0].BUSS_CODE;
          this.S_CTCP_CODE = usserAuth[0].CTCP_CODE;
          await this.changeDetailBussType(this.S_CTCP_CODE);

          this.getListData(1);
        }
      }
    },

    computed: {

      dateRangeText () {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + " ~ " + newEndDate;
      },
      
      initHeaders(){
        return {
        };
      },
      paramData(){
        return {
        };
      }
    },
  };
</script>